<template>
  <v-app>
    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />

    <!-- <default-settings /> -->
  </v-app>
</template>

<script>
import { set, get } from 'vuex-pathify'

export default {
  name: "DefaultLayout",
  data () {
    //   const entrycode = { ...get("route/params@entrycode") }
    //   console.log('In the data: ')
    //   console.log(entrycode)
    const entrycode = this.$route.params.entrycode
    console.log(entrycode)

      return {
        items: [
        {
            title: 'Dashboard',
            icon: 'mdi-view-dashboard',
            to: '/',
        },
        {
            title: 'Edit Incident',
            icon: 'mdi-pencil',
            items: [
            {
                title: 'Edit informant',
                to: `/editincident/${entrycode}/informant`
            },
            {
                title: 'Notification of event',
                to: `/editincident/${entrycode}/notification`
            },
            {
                title: "Event's location",
                to: `/editincident/${entrycode}/poisonevent`
            },
            {
                title: 'Dead animal found',
                to: `/editincident/${entrycode}/deadanimalfound`
            },
            {
                title: 'Alive animal found',
                to: `/editincident/${entrycode}/aliveanimalfound`
            },
            {
                title: 'Poison bait',
                to: `/editincident/${entrycode}/poisonbaitinfo`
            },
            {
                title: 'Evidence',
                to: `/editincident/${entrycode}/evidenceinfo`
            },
            {
                title: 'Motive',
                to: `/editincident/${entrycode}/motive`
            },
            {
                title: 'Authorities involved',
                to: `/editincident/${entrycode}/authoritiesinv`
            },
            {
                title: 'Antipoison dog unit',
                to: `/editincident/${entrycode}/antidogunit`
            },
            {
                title: 'Samples/Evidence collected',
                to: `/editincident/${entrycode}/samplescol`
            },
            {
                title: 'Disposal of dead animals',
                to: `/editincident/${entrycode}/disposaldead`
            },
            {
                title: 'Other death reasons',
                to: `/editincident/${entrycode}/otherreasons`
            },
            {
                title: 'Official complaint',
                to: `/editincident/${entrycode}/officialcomp`
            },
            {
                title: 'Autopsy, toxicological analysis',
                to: `/editincident/${entrycode}/autopsy`
            },
            {
                title: 'Toxins used',
                to: `/editincident/${entrycode}/toxinsused`
            },
            {
                title: 'General comments',
                to: `/editincident/${entrycode}/gencomments`
            },
            {
                title: 'Extra public info',
                to: `/editincident/${entrycode}/extrainfo`
            },
            {
                title: 'Upload images',
                to: `/editincident/${entrycode}/images`
            },
            {
                title: 'Upload documents',
                to: `/editincident/${entrycode}/documents`
            }
            ]
        },
        ]
    }
  },
  computed: {
     entrycode: get("route/params@entrycode")
  },

  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        "@/components/dashboard/AppBar"
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        "@/components/dashboard/Drawer"
      ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        "@/components/dashboard/Footer"
      ),
    DefaultSettings: () =>
      import(
        /* webpackChunkName: "default-settings" */
        "@/components/dashboard/Settings"
      ),
    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        "@/components/dashboard/View"
      ),
  },
  created () {
    this.$store.set('app/items', this.items)
    this.$store.set('app/viewcol', 6)
    console.log('The entrycode is: ' + this.entrycode)
    console.log(this.entrycode)
  }
};
</script>
